import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "wrapper d-flex flex-column flex-row-fluid"
}
const _hoisted_3 = { class: "content flex-row-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_CartDrawer = _resolveComponent("CartDrawer")!
  const _component_KTExplore = _resolveComponent("KTExplore")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!
  const _component_KTFooter = _resolveComponent("KTFooter")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ rtl: _ctx.locale === 'ar' }, "d-flex flex-column min-vh-100"])
  }, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
        (_ctx.subheaderDisplay)
          ? (_openBlock(), _createBlock(_component_KTToolbar, {
              key: 0,
              breadcrumbs: _ctx.breadcrumbs,
              title: _ctx.pageTitle
            }, null, 8, ["breadcrumbs", "title"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          id: "kt_content",
          class: _normalizeClass(["d-flex flex-column-fluid align-items-start", {
            'container-fluid': _ctx.contentWidthFluid,
            'container-xxl': !_ctx.contentWidthFluid,
          }])
        }, [
          (_ctx.asideEnabled)
            ? (_openBlock(), _createBlock(_component_KTAside, {
                key: 0,
                lightLogo: _ctx.themeLightLogo,
                darkLogo: _ctx.themeDarkLogo
              }, null, 8, ["lightLogo", "darkLogo"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ])
        ], 2)
      ])
    ]),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_CartDrawer, { ref: "cartDrawer" }, null, 512),
    _createVNode(_component_KTExplore),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp),
    _createVNode(_component_KTFooter)
  ], 2))
}