const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard.dashboard",
        route: "/dashboard",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "dashboard.report",
        route: "/bulletin",
        report: true,
      },
      {
        heading: "dashboard.canteen",
        route: "/cantine",
        fontIcon: "bi-layers",
      },
      {
        heading: "dashboard.schedule",
        route: "/emplois",
      },
      {
        heading: "dashboard.avertissements",
        route: "/avertissement",
      },
      {
        heading: "dashboard.attendance",
        route: "/attendance",
      },
      {
        heading: "dashboard.payment",
        route: "/paiement",
      },
      {
        heading: "dashboard.homeworks",
        route: "/devoirs",
      },
      {
        heading: "dashboard.courses",
        route: "/cours",
      },
      {
        heading: "dashboard.actualites",
        route: "/actualites",
      },
      {
        heading: "calendarApp",
        route: "/calendar",
      },
      {
        heading: "dashboard.DigitalLibrary",
        route: "/library",
      },
      {
        heading: "MarketPlace",
        route: "/Market",
      },
    ],
  },
];

export default DocMenuConfig;
