
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "kt-notify",
  props: {
    classes: { type: String },
    icon: { type: String },
    title: { type: String },
    body: { type: String },
    button: { type: String },
    buttonLabel: { type: String, default: "Button" },
    buttonUrl: { type: String, default: "#" },
    buttonModalId: { type: String },
    color: { type: String, default: "primary" },
    padding: { type: String, default: "p-6" },
    dismissable: { type: Boolean, default: false }, // New prop to control visibility of close button
  },
  setup() {
    const visible = ref(true); // Manage visibility

    const dismiss = () => {
      visible.value = false; // Close the notification
    };

    return {
      visible,
      dismiss,
    };
  },
});
